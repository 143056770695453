import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import ImageURL from "../../images/image-banner.jpeg";

import Header, { LogoLink, NavLinks, NavLink as NavLinkBase } from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`justify-between`}
  ${LogoLink} {
    ${tw`mr-8 pb-0`}
  }
`;

const NavLink = tw(NavLinkBase)`
  sm:text-sm sm:mx-6
`;

const Container = tw.div`relative -mx-8 -mt-8`;
const TwoColumn = tw.div`flex flex-col lg:flex-row bg-gray-100`;
const LeftColumn = tw.div`ml-8 mr-8 xl:pl-10 py-8`;
const RightColumn = styled.div`
  background-image: url(${ImageURL}); /* Usando a variável ImageURL aqui */
  ${tw`bg-green-500 bg-cover bg-center xl:ml-24 h-96 lg:h-auto lg:w-1/2 lg:flex-1`}
`;


const Content = tw.div`mt-24 lg:mt-24 lg:mb-24 flex flex-col sm:items-center lg:items-stretch`;
const Heading = tw.h1`text-3xl sm:text-5xl md:text-6xl lg:text-5xl font-black leading-none`;
const Paragraph = tw.p`max-w-md my-8 lg:my-5 lg:my-8 sm:text-lg lg:text-base xl:text-lg leading-loose`;

const Actions = styled.div`
  ${tw`mb-8 lg:mb-0`}
  .action {
    ${tw`text-center inline-block w-full sm:w-48 py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  }
  .primaryAction {
    ${tw`bg-green-500 text-gray-100 hover:bg-green-700`}
  }
  .secondaryAction {
    ${tw`mt-4 sm:mt-0 sm:ml-4 bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800`}
  }
`;

export default ({
  navLinks = [
    <NavLinks key={1}>
      <NavLink href="#sobre-nos">Sobre nós</NavLink>
      <NavLink href="#solucoes">Soluções</NavLink>
      <NavLink href="#depoimentos">Depoimentos</NavLink>
      <NavLink href="#duvidas">Dúvidas</NavLink>
    </NavLinks>
  ],
  heading = (
    <>
      Qualidade
      <wbr />
      <br />
      <span tw="text-blue-500"> e excelência</span>
    </>
  ),
  description1 = "Oferecemos a melhor qualidade em comunicação visual. ",
  description2 = "Criações, produtos e instalações.",
  description3 = "Trabalhamos com comprometimento e buscamos garantir uma entrega de qualidade.",
  primaryActionUrl = "https://wa.me/5511989663891?text=Ol%C3%A1%2C%20tudo%20bem%3F%0AQue%20bom%20ver%20voc%C3%AA%20por%20aqui!%20%F0%9F%A5%B0%0AEssa%20%C3%A9%20uma%20mensagem%20autom%C3%A1tica%20e%2C%20assim%20que%20poss%C3%ADvel%2C%20voc%C3%AA%20ser%C3%A1%20atendido%20por%20um%20dos%20nossos%20atendentes.%0A%0AEnquanto%20isso%2C%20acesse%20nosso%20cat%C3%A1logo%20e%20conhe%C3%A7a%20nossos%20produtos",
  primaryActionText = "WhatsApp",
}) => {
  return (
    <Container id="sobre-nos">
      <TwoColumn>
        <LeftColumn>
          <StyledHeader links={navLinks} collapseBreakpointClass="sm" />
          <Content>
            <Heading>{heading}</Heading>
            <Paragraph>
              {description1}
              <br />
              {description2}
              <br />
              {description3}
            </Paragraph>
            <Actions>
              <a href={primaryActionUrl} className="action primaryAction">
                {primaryActionText}
              </a>
            </Actions>
          </Content>
        </LeftColumn>
        <RightColumn></RightColumn>
      </TwoColumn>
    </Container>
  );
};
